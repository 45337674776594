$BUS: rgb(3, 154, 229);
$FERRY: rgb(0, 170, 193);
$SUBWAY: rgb(255, 145, 0);
$RAIL: rgb(198, 40, 40);
$TRAM: rgb(67, 160, 72);

.App {
  height: 100vh;
  width: 100%;

  .fa-bus {
    color: $BUS;
  }

  .fa-ship {
    color: $FERRY;
  }

  .fa-subway {
    color: $SUBWAY;
  }

  .fa-train {
    color: $RAIL;
  }

  .fa-tram {
    color: $TRAM;
  }

  .loader {
    margin: 0 auto;
    margin-top: 50%;
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
